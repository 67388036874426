import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { sortAccountTableData } from "../../../utils/common";
import {
  SortOrder,
  IColumnState
} from "../../../types/types";

import MyAccountTable from "./MyAccountTable/MyAccountTable";
import { aboColumnMapping } from '../../../constants/dataConstants';

interface IAccountTableRow {
  affiliateCode: string,
  aboNumber: string,
  name: string,
  localName: null,
  marketName: string,
  currentAward: {
    awardCode: string,
    awardName: string
  },
  trackingAward: {
    awardCode: string,
    awardName: string,
    awardTagCode: number,
    awardTagName: string
  },
  monthlyRevenue: {
    revenue: number,
    revenuePerformancePercentage: string | number,
    localRevenue: number,
    localRevenueCurrency: string,
    localRevenuePerformancePercentage: string | number
  },
  monthlyContributor: {
    contributor: number,
    contributorPerformancePercentage: string | number
  },
  annualRevenue: {
    revenue: number,
    revenuePerformancePercentage: string | number,
    localRevenue: number,
    localRevenueCurrency: string,
    localRevenuePerformancePercentage: string | number
  },
  annualContributor: {
    contributor: number,
    contributorPerformancePercentage: string | number
  }
}

type ComponentProps = {
  data: any;
  viewDetails: any;
}

const AccountTableContainer = ({ data, viewDetails }: ComponentProps) => {
  const { t } = useTranslation();
  const viewId = useAppSelector((state: any) => state.viewIdDetails.viewId.defaultViewId);

  const defaultSortState = {
    "name": "nosort" as SortOrder,
    "currentAward.awardName": "nosort" as SortOrder,
    "trackingAward.awardName": "nosort" as SortOrder,
    "monthlyRevenue.revenue": "nosort" as SortOrder,
    "annualRevenue.revenue": "nosort" as SortOrder,
    "monthlyContributor.contributor": "nosort" as SortOrder,
    "annualContributor.contributor": "nosort" as SortOrder
  };
  const [tableDataList, setTableDataList] = useState<any[] | []>([]);// TODO: IAccountTableRow >> add all type of expected object[with all expected column of keys values] for the table
  const [columnState, setColumnState] = useState<IColumnState>(defaultSortState);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);

  useEffect(() => {
    const intialSortData = getSortedData();
    setTableDataList(intialSortData);
  }, [data, columnState]);

  const getSortedData = () => {
    let sortedData = data?.body?.abos || [];

    for (const [column, order] of Object.entries(columnState)) {
      if (order !== 'nosort') {
        sortedData = sortAccountTableData(sortedData, column, order);
        break; // Only sort by one column at a time
      }
    }

    return sortedData;
  };

  // Add new table if you want to display
  const tableColumnData = [t("Account_table_headers.IBO_Info"), t("Account_table_headers.Tracking_Award"), t("Account_table_headers.Monthly_Revenue"), t("Account_table_headers.Monthly_Contributors"),t("Account_table_headers.Annual_Revenue YTD"), t("Account_table_headers.Annual_Contributors")]    
  // Maintain order for the respective column of tableColumnData
  // add all expected table key path for the respective column to be sorted
  const tableColumnNameMapper = ["name", "trackingAward.awardName", "monthlyRevenue.revenue", "monthlyContributor.contributor", "annualRevenue.revenue", "annualContributor.contributor"];

  const kpis = viewDetails?.kpis;

  const tableColumnList = kpis?.map((kpi:any) => {
    return t(`Account_table_headers.${aboColumnMapping[kpi.code].columnName}`);
  });

  const columnNameMapperList = kpis?.map((kpi:any) => {
    return aboColumnMapping[kpi.code].valueMapping;
  });

  const filteredTableColumnData=  viewId==1 ? tableColumnData :[t("Account_table_headers.IBO_Info"),...tableColumnData.filter((col) =>
  tableColumnList?.includes(col))];

  const filteredColumnNamesMapper= viewId==1 ? tableColumnNameMapper :["name",...tableColumnNameMapper.filter((col) =>
  columnNameMapperList?.includes(col))];

  return <MyAccountTable tableData={tableDataList} isLoading={false} columnState={columnState} setColumnState={setColumnState} tableColumnData={filteredTableColumnData} tableColumnNameMapper={filteredColumnNamesMapper} rowStepperLimit={15} isSortable={false} />
};

export default AccountTableContainer;
