import React from 'react';
import { icons, Icon as SvgIcon } from './SvgData';

type IconProps = {
  name: string;
  size?: number;
  color?: string;
  onClick?: () => void;
  className?: string;
};

const IconComponent: React.FC<IconProps> = ({ name, size = 24, color = '#000', onClick, className }) => {
  const icon: SvgIcon | undefined = icons.find(icon => icon.name === name);

  if (!icon) {
    console.warn(`Icon with name "${name}" not found.`);
    return null;
  }

  const { viewWidth = 18, viewHeight = 28, paths = [], pathColors = [] } = icon;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${viewWidth} ${viewHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      {paths.map((d, index) => (
        <path
          key={index}
          d={d}
          fill={color || pathColors[index] || '#000'}
        />
      ))}
    </svg>
  );
};

export default IconComponent;
