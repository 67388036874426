import React from "react";
import styles from "./Button.module.scss";
import Spinner from "../Spinner/Spinner";

interface ButtonProps {
  id?: string;
  onClick: () => void;
  label: string;
  style?: "filled" | "unfilled";
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  id,
  onClick,
  label,
  style = "unfilled",
  isDisabled = false,
  isLoading = false,
}) => {
  const buttonClass = style;

  return (
    <button
      id={id}
      className={`button ${isDisabled ? `disabled ${styles.disabled}` : ""} ${styles.button} ${buttonClass}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {isLoading ? (
        <span className={styles.spinner}>
          <Spinner loadingSize={`medium`} />
        </span>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
