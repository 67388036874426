import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./GoalActionPlanDocument.module.scss";
import isEmpty from "lodash/isEmpty";
import { useAppSelector } from "../../../../redux/hooks";
import {
  useGetActionPlanDocsListQuery,
  useDeleteActionPlanDocMutation,
} from "../../../../redux/services/actionPlansDocumentAPI";
import {
  IGoalActionPlanColumnDetails,
  ISuccessionPlanColumnDetails,
  ITableColumnState,
} from "../../../../types/types";
import { RootState } from "../../../../redux/store";
import { sortTableData, findKeysInArray } from "../../../../utils/common";
import DocumentTabel from "../../../common/DocumentTable/DocumentTable";
import { useGetOptionsListQuery } from "../../../../redux/services/optionMasterAPI";
import { useSelector } from "react-redux";
import Modal from "../../../common/Modal/Modal";

import UploadComponent from "../../../common/UploadComponent/UploadComponent";
import uploadIconLight from "../../../../assets/images/LightTheme/upload_light.svg";
import uploadIconDark from "../../../../assets/images/DarkTheme/upload_dark.svg";
import UploadedDocView from "../../../common/UploadedDocView/UploadedDocView";
import { SuccessionPlanFileType } from "../../../../types/types";
import { useUploadActionPlanDocsMutation } from "../../../../redux/services/actionPlansDocumentAPI";
import SuccessionDocLoader from "../../../common/Loaders/SuccessionDocLoader/SuccessionDocLoader";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import { disableEnableScroll } from "../../../../utils/disableEnableScroll";

const GoalActionPlanDocument = () => {
  const { t } = useTranslation();
  const [goalActionPlanList, setGoalActionPlanListList] = useState<
    IGoalActionPlanColumnDetails[] | []
  >([]);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const uploadIcon =
    currentTheme === "light" ? uploadIconLight : uploadIconDark;

  const [modalOpen, setModalOpen] = useState(false);
  const [fileToBeUploaded, setFileToBeUploaded] =
    useState<SuccessionPlanFileType>();
  const [documentTypeList, setDocumentTypeList] = useState<DropdownItem[]>();
  const [isAPIError, setIsAPIError] = useState<boolean>(false);

  const currentYear = new Date()?.getFullYear()?.toString()?.slice(2);
  const selectedYear =
    useSelector((state: RootState) => state.selectedYear.selectedYear) ||
    currentYear;
  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  const { isFetching, data, isError } = useGetActionPlanDocsListQuery(
    {
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
      year: selectedYear,
    },
    { skip: isAPIError }
  );

  const [columnState, setColumnState] = useState<
    ITableColumnState<IGoalActionPlanColumnDetails>[]
  >([]);

  const defaultColumnName =
    columnState[0]?.sortOrder === "nosort" || isEmpty(columnState)
      ? "updatedDate"
      : columnState[0]?.column;
  const defaultSort =
    columnState[0]?.sortOrder === "nosort" || isEmpty(columnState)
      ? "desc"
      : columnState[0]?.sortOrder;
  const [
    updateGoalActionPlanDocsList,
    { isLoading: isLoadingDeleteActionPlanDocument, isError: isErrorInDelete },
  ] = useDeleteActionPlanDocMutation();

  // Pass exact key names from response to table data
  const tableColumnData = ["aboActionPlanId", "name", "size", "updatedDate"];

  const handleAPIError = () => {
    setIsAPIError(true);
    setGoalActionPlanListList([]);
  };

  useEffect(() => {
    disableEnableScroll(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    if (isError || isErrorInDelete) handleAPIError();
  }, [isError, isErrorInDelete]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (isValidResponse(data?.statusCode)) {
        let prepareTableData = findKeysInArray(
          data?.body ?? [],
          tableColumnData
        );
        // create a common key to use ID's different name
        prepareTableData?.forEach((docRow) => {
          docRow["commonIdKey"] = docRow.aboActionPlanId;
        });
        const intialSortData = sortTableData(
          prepareTableData,
          defaultColumnName,
          defaultSort
        );
        setGoalActionPlanListList(intialSortData);
      } else {
        handleAPIError();
      }
    }
  }, [data, isFetching]);

  const deleteDocument = async (docName: string, id: string | number) => {
    const response: any = await updateGoalActionPlanDocsList({
      id: id,
    });

    if (!isValidResponse(response?.data?.statusCode)) {
      console.error(
        `Deletion failed for the Goals Action plan Doc : ${docName} Id: ${id} `
      );
      handleAPIError();
    }
  };

  const { data: optionListdata } = useGetOptionsListQuery({
    applicationCode: "amw",
    optionMasterName: "succession-documenttype",
  });

  useEffect(() => {
    setDocumentTypeList(optionListdata?.body?.options ?? []);
  }, [optionListdata]);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
    setFileToBeUploaded(undefined);
  };

  const handlePersistSelectedFile = (file: SuccessionPlanFileType) => {
    setFileToBeUploaded(file);
  };

  return (
    <section
      className={`action_plan_document_container ${styles.action_plan_document_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {isFetching || isLoadingDeleteActionPlanDocument ? (
        <SuccessionDocLoader num={1} heading={t("documents")} />
      ) : isAPIError ? (
        <>
          <div className={styles.title} data-testid="action_plan_doc_testId">
            {t("documents")}
          </div>
          <div className={styles.upload_btn_wrapper}>
            <div className={`table_sub_title ${styles.sub_title}`}>
              {t("documentSubTitle")}
            </div>
          </div>
          <ErrorInPageScreen
            handleClickTryAgain={() => {
              setIsAPIError(false);
            }}
          />
        </>
      ) : (
        <>
          <div className={styles.title} data-testid="action_plan_doc_testId">
            {t("documents")}
          </div>
          <div className={styles.upload_btn_wrapper}>
            <div className={`table_sub_title ${styles.sub_title}`}>
              {t("documentSubTitle")}
            </div>
            <div
              role="button"
              className={styles.icon_wrapper}
              onClick={handleModalToggle}
            >
              <img
                src={uploadIcon}
                alt="upload"
                className={styles.upload_icon}
                data-testid="upload_icon_testID"
              />
              <span className={`label ${styles.label}`}>{t("upload")}</span>
            </div>
          </div>
          <DocumentTabel
            tableData={goalActionPlanList}
            isLoading={isFetching}
            columnState={columnState}
            setColumnState={setColumnState}
            deleteDocument={deleteDocument}
            rowLimit={5}
            columnsToBeVisible={["docName", "date", "actions"]}
            downloadDocUrlParam={"actionplan"}
          />
        </>
      )}

      <Modal showDrawer={modalOpen} onClose={handleModalToggle}>
        {isEmpty(fileToBeUploaded) ? (
          <UploadComponent
            handlePersistSelectedFile={handlePersistSelectedFile}
          />
        ) : (
          <UploadedDocView
            file={fileToBeUploaded}
            documentTypeList={documentTypeList}
            handleCancelCallback={handleModalToggle}
            handleTogglePopupCallback={() => {
              handleModalToggle();
            }}
            handleClickDeleteCallback={() => {
              setFileToBeUploaded(undefined);
            }}
            isEnabledDropdown={false}
            section="analysis_doc"
            uploadMutation={useUploadActionPlanDocsMutation}
          />
        )}
      </Modal>
    </section>
  );
};

export default GoalActionPlanDocument;
