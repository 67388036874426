export type Icon = {
  name: string;
  viewWidth?: number;
  viewHeight?: number;
  paths?: string[];
  pathColors?: string[];
};

export const icons: Icon[] = [
  {
    name: 'search',
    viewWidth: 28,
    viewHeight: 28,
    paths: [
      'M19.25 11.75C19.25 9.07812 17.7969 6.64062 15.5 5.28125C13.1562 3.92188 10.2969 3.92188 8 5.28125C5.65625 6.64062 4.25 9.07812 4.25 11.75C4.25 14.4688 5.65625 16.9062 8 18.2656C10.2969 19.625 13.1562 19.625 15.5 18.2656C17.7969 16.9062 19.25 14.4688 19.25 11.75ZM17.7969 19.4375C16.1094 20.75 14 21.5 11.75 21.5C6.35938 21.5 2 17.1406 2 11.75C2 6.40625 6.35938 2 11.75 2C17.0938 2 21.5 6.40625 21.5 11.75C21.5 14.0469 20.7031 16.1562 19.3906 17.8438L25.6719 24.0781C26.0938 24.5469 26.0938 25.25 25.6719 25.6719C25.2031 26.1406 24.5 26.1406 24.0781 25.6719L17.7969 19.4375Z',
    ],
    pathColors: [],
  },
  // Add more icons here...
];
