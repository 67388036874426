import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import styles from "./UploadedDocView.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import { SuccessionPlanFileType } from "../../../types/types";
import formatFileSize from "../../../utils/formatFileSize";
import Spinner from "../Spinner/Spinner";
import deleteIconLight from "../../../assets/images/LightTheme/delete_light.svg";
import deleteIconDark from "../../../assets/images/DarkTheme/delete_dark.svg";

interface UploadedDocumentProps {
  file: SuccessionPlanFileType | undefined;
  documentTypeList: DropdownItem[] | undefined;
  handleCancelCallback: () => void;
  handleTogglePopupCallback: () => void;
  handleClickDeleteCallback: () => void;
  isEnabledDropdown?: boolean;
  section?:string;
  uploadMutation:any;
}

const UploadedDocView: React.FC<UploadedDocumentProps> = ({
  file,
  documentTypeList,
  handleCancelCallback,
  handleTogglePopupCallback,
  handleClickDeleteCallback,
  isEnabledDropdown,
  section,
  uploadMutation
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const selectedYear:string = useAppSelector((state: any) => state.selectedYear.selectedYear);
  const deleteIcon =
    currentTheme === "light" ? deleteIconLight : deleteIconDark;

  const [documentType, setDocumentType] = useState<DropdownItem>();
  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);
  const [isErrorInUpload, setIsErrorInUpload] = useState(false);

  const [
    uploadDocument,
    { isLoading: isUploadLoading, isError },
  ] = uploadMutation();


  useEffect(() => {
    (isError) && setIsErrorInUpload(true);
  }, [isError]);

  const handleClickCancel = () => {
    setIsErrorInUpload(false);
    handleCancelCallback();
  };

  const handleClickSave = async () => {
    setIsErrorInUpload(false);

    const formData: any = new FormData();
	if(section=="succession_plan_doc")
	{
    formData?.append("documentType", documentType?.code ?? "");
    formData?.append("file", file?.imgFile);
   }else{
	formData?.append("performanceYear", selectedYear ?? "");
    formData?.append("file", file?.imgFile);
	}


    const response: any = await uploadDocument({
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
      payload: formData,
    });

    if (response?.data?.statusCode === 200) {
      handleTogglePopupCallback();
    } else {
      setIsErrorInUpload(true);
    }
  };

  const handleSelectDocType = (type: DropdownItem) => {
    setDocumentType(type);
  };

  return (
    <section
      className={`uploaded_document_wrapper ${styles.uploaded_document_wrapper}`}
    >
      <div className={`${styles.modal_title}`}>{t("uploadDocument")}</div>
      <div className={styles.inner_wrapper}>
		{isEnabledDropdown && (
        <div className={styles.dropdown_wrapper}>
          <Dropdown
            items={documentTypeList}
            selectedItem={documentType}
            handleSelectItemCallback={handleSelectDocType}
          />
        </div>)}
        <div className={styles.file_info}>
          <div className={styles.file_info_wrapper}>
            <div className={styles.file_info_inner_wrapper}>
              <div className={`file_name ${styles.file_name}`}>
                {file?.imgFile?.name ?? ""}
              </div>
              <div
                className={`file_details_wrapper ${styles.file_details_wrapper}`}
              >
                <span className={`file_size ${styles.file_size}`}>
                  {formatFileSize(file?.imgFile?.size)}
                </span>
                {isUploadLoading && (
                  <>
                    <span className={styles.spinner}>
                      <Spinner />
                    </span>
                    <span className={`uploading_text ${styles.uploading_text}`}>
                      {t("uploadingText")}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className={styles.delete_icon_wrapper}>
              <img
                src={deleteIcon}
                alt="delete"
                className={styles.delete_icon}
                onClick={() => handleClickDeleteCallback()}
              />
            </div>
          </div>
          {isErrorInUpload && (
            <div className={`error_container ${styles.error_container}`}>
              {t("fileUploadErrorMsg")}
            </div>
          )}
        </div>
        <div className={styles.btn_wrapper}>
          <Button
            id="cancel_btn"
            onClick={handleClickCancel}
            label={t("btnCancel")}
            style="unfilled"
          />
          <Button
            id="save_btn"
            onClick={handleClickSave}
            label={t("btnSave")}
            style="filled"
            isDisabled={isEnabledDropdown && isEmpty(documentType) || isUploadLoading}
          />
        </div>
      </div>
    </section>
  );
};

export default UploadedDocView;
