import styles from "./Spinner.module.scss";

type ComponentProps = {
  loadingSize?: string;
};

const Spinner = ({ loadingSize }: ComponentProps) => (
  <span
    className={`spinner ${styles.loader} ${loadingSize === "medium" ? styles.medium : ""}`}
  ></span>
);

export default Spinner;
