import React from "react";
import styles from "./ComingSoonBanner.module.scss";
import ComingSoonDark from "../../../assets/images/DarkTheme/coming_soon_dark.svg";
import ComingSoonLight from "../../../assets/images/LightTheme/coming_soon_light.svg";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";

const ComingSoonBanner: React.FC = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const ComingSoonBanner =
    currentTheme === "light" ? ComingSoonLight : ComingSoonDark;
  return (
    <div className={`coming_soon_section ${styles.coming_soon_section}`}>
      <img
        src={ComingSoonBanner}
        alt="Coming Soon"
        className={styles.coming_soon_image}
      />
      <p className={styles.coming_soon_text}>{t("coming_soon")}</p>
    </div>
  );
};

export default ComingSoonBanner;
