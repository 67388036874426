import { currencyLocaleMappings } from '../constants/dataConstants';
export const CurrencyFormatter = (currency: string, revenue: number ) => {

	// Define Thai currency codes
	const thaiCurrencyCodes = ["BHT", "TBH", "THB"];
	const currencyCode = thaiCurrencyCodes.includes(currency) ? "THB" : currency;
    const locale=currencyLocaleMappings[currencyCode];

	return currency ?
	   revenue.toLocaleString(locale, {
		  style: 'currency',
		  currency: currencyCode,
		  maximumFractionDigits: 2,
		  minimumFractionDigits: 2,
		}) : revenue
	 };