import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./KeyKpis.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import { isEmpty } from "lodash";
import ComingSoonBanner from "../../../common/ComingSoonBanner/ComingSoonBanner";

const KeyKpis = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);

  const isLoadingKeyKpis = false;
  const keyKPIsData: any = [];
  return (
    <div className={`keyKpi_wrapper ${styles.keyKpi_wrapper}`}>
      <div className={styles.heading_area}>
        <span className={styles.heading} data-testid="key_kpi_bg_heading_testID">
          {t("key_kpis")}
        </span>
      </div>
      {isLoadingKeyKpis || isEmpty(keyKPIsData) ? (
        <div className={styles.empty_state}>
          <div className={`table_no_data ${styles.empty_state_msg}`}>
            <ComingSoonBanner />
          </div>
        </div>
      ) : (
        <>
          <div className={`keyKpi_section ${styles.keyKpi_section}`}>

          </div>
        </>
      )}
    </div>
  );
};

export default KeyKpis;
