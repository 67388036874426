import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProbabilityModeling.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import { isEmpty } from "lodash";
import ComingSoonBanner from "../../../common/ComingSoonBanner/ComingSoonBanner";


const ProbabilityModeling = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);

  const isLoadingLegAnalysis = false;
  const probabilityModelingData: any = [];
  return (
    <div className={`probability_modeling_wrapper ${styles.probability_modeling_wrapper}`}>
      <div className={styles.heading_area}>
        <span className={styles.heading} data-testid="probability_modeling_bg_heading_testID">
          {t("probability_modeling")}
        </span>
      </div>
      {isLoadingLegAnalysis || isEmpty(probabilityModelingData) ? (
        <div className={styles.empty_state}>
          <div className={`table_no_data ${styles.empty_state_msg}`}>
            <ComingSoonBanner />
          </div>
        </div>
      ) : (
        <>
          <div className={`probability_modeling_section ${styles.probability_modeling_section}`}>
          </div>
        </>
      )}
    </div>
  );
};

export default ProbabilityModeling;
