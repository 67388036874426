import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import styles from "./SuccessionPlan.module.scss";
import TableContainer from "./SuccessionPlanTable/Table";
import Modal from "../../../common/Modal/Modal";
import UploadComponent from "../../../common/UploadComponent/UploadComponent";
import { useAppSelector } from "../../../../redux/hooks";
import uploadIconLight from "../../../../assets/images/LightTheme/upload_light.svg";
import uploadIconDark from "../../../../assets/images/DarkTheme/upload_dark.svg";
import UploadedDocView from "../../../common/UploadedDocView/UploadedDocView";
import { SuccessionPlanFileType } from "../../../../types/types";
import { useGetOptionsListQuery } from "../../../../redux/services/optionMasterAPI";
import {
  useDeleteSuccessionPlanDocsItemMutation,
  useGetSuccessionPlanDocsListQuery,
  useUploadSuccessionPlanMutation,
} from "../../../../redux/services/successionPlanAPI";
import SuccessionDocLoader from "../../../common/Loaders/SuccessionDocLoader/SuccessionDocLoader";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import { disableEnableScroll } from "../../../../utils/disableEnableScroll";

const SuccessionPlan: React.FC = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const uploadIcon =
    currentTheme === "light" ? uploadIconLight : uploadIconDark;

  const [modalOpen, setModalOpen] = useState(false);
  const [fileToBeUploaded, setFileToBeUploaded] =
    useState<SuccessionPlanFileType>();
  const [documentTypeList, setDocumentTypeList] = useState<DropdownItem[]>();
  const [isAPIError, setIsAPIError] = useState(false);

  useEffect(() => {
    disableEnableScroll(modalOpen);
  }, [modalOpen]);

  const { data } = useGetOptionsListQuery({
    applicationCode: "amw",
    optionMasterName: "succession-documenttype",
  });

  const {
    isFetching,
    isError,
    data: successionPlanListRes,
  } = useGetSuccessionPlanDocsListQuery(
    {
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
    },
    {
      skip: isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    updateSuccessionPlanDocsList,
    { isLoading: isLoadingDeleteSuccessioPlan, isError: isErrorInDelete },
  ] = useDeleteSuccessionPlanDocsItemMutation();

  const deleteDocument = async (docName: string, id: string | number) => {
    const response: any = await updateSuccessionPlanDocsList({
      id: id,
    });

    if (response?.data?.statusCode !== 200) {
      console.error(`Deletion failed for the Doc : ${docName} Id: ${id} `);
      handleAPIError();
    }
  };

  const handleAPIError = () => {
    setIsAPIError(true);
  };

  useEffect(() => {
    if (isError || isErrorInDelete) handleAPIError();
  }, [isError, isErrorInDelete]);

  useEffect(() => {
    if (!isEmpty(successionPlanListRes)) {
      if (!isValidResponse(successionPlanListRes?.statusCode)) {
        handleAPIError();
      }
    }
  }, [successionPlanListRes, isFetching]);

  useEffect(() => {
    setDocumentTypeList(data?.body?.options ?? []);
  }, [data]);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
    setFileToBeUploaded(undefined);
  };

  const handlePersistSelectedFile = (file: SuccessionPlanFileType) => {
    setFileToBeUploaded(file);
  };

  return (
    <section
      className={`succession_plan_container ${styles.succession_plan_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {isFetching || isLoadingDeleteSuccessioPlan ? (
        <SuccessionDocLoader num={1} heading={t("succession_plan")} />
      ) : (
        <>
          {" "}
          <div className={styles.title} data-testid="succession_plan_testId">
            {t("succession_plan")}
          </div>
          <div className={styles.description_txt}>
            {t("successionTableDescText")}
          </div>
          {isAPIError ? (
            <ErrorInPageScreen
              handleClickTryAgain={() => {
                setIsAPIError(false);
              }}
            />
          ) : (
            <>
              <div className={styles.upload_btn_wrapper}>
                <div className={`table_sub_title ${styles.sub_title}`}>
                  {t("documents")}
                </div>
                <div
                  className={styles.icon_wrapper}
                  onClick={handleModalToggle}
                >
                  <img
                    src={uploadIcon}
                    alt="upload"
                    className={styles.upload_icon}
                    data-testid="upload_icon_testID"
                  />
                  <span className={`label ${styles.label}`}>{t("upload")}</span>
                </div>
              </div>
              <TableContainer
                documentTypeList={documentTypeList}
                data={successionPlanListRes}
                isError={isError}
                deleteDocument={deleteDocument}
              />
            </>
          )}
        </>
      )}

      <Modal showDrawer={modalOpen} onClose={handleModalToggle}>
        {isEmpty(fileToBeUploaded) ? (
          <UploadComponent
            handlePersistSelectedFile={handlePersistSelectedFile}
          />
        ) : (
          <UploadedDocView
            file={fileToBeUploaded}
            documentTypeList={documentTypeList}
            handleCancelCallback={handleModalToggle}
            handleTogglePopupCallback={() => {
              handleModalToggle();
            }}
            handleClickDeleteCallback={() => {
              setFileToBeUploaded(undefined);
            }}
            isEnabledDropdown={true}
            section="succession_plan_doc"
            uploadMutation={useUploadSuccessionPlanMutation}
          />
        )}
      </Modal>
    </section>
  );
};

export default SuccessionPlan;
