import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import styles from "./UploadComponent.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import uploadDocIconLight from "../../../assets/images/LightTheme/upload_img_light.svg";
import uploadDocIconDark from "../../../assets/images/DarkTheme/upload_img_dark.svg";
// import { ALLOWED_FILE_TYPES } from "../../../constants/dataConstants";
import { SuccessionPlanFileType } from "../../../types/types";

interface UploadComponentProps {
  handlePersistSelectedFile: (file: SuccessionPlanFileType) => void;
}

const UploadComponent: React.FC<UploadComponentProps> = ({
  handlePersistSelectedFile,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const dragDropRef = useRef<HTMLInputElement | null>(null);
  const allowedFileSize = 10 * 1024 * 1024;
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedFile, setSelectedFile] = useState<SuccessionPlanFileType>();

  const handleDragEnter = (ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    const files = ev?.dataTransfer?.files;

    if (files && files.length) {
      setIsDragOver(false);
      onFileChange({
        target: {
          files,
        },
      });
    }
  };

  useEffect(() => {
    !isEmpty(selectedFile) && handlePersistSelectedFile(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    if (dragDropRef?.current?.addEventListener) {
      dragDropRef.current.addEventListener("dragover", handleDragEnter);
      dragDropRef.current.addEventListener("drop", handleDrop);
      dragDropRef.current.addEventListener("dragenter", handleDragEnter);
      dragDropRef.current.addEventListener("dragleave", handleDragLeave);
    }

    return () => {
      if (dragDropRef?.current?.removeEventListener) {
        dragDropRef.current.removeEventListener("dragover", handleDragEnter);
        dragDropRef.current.removeEventListener("drop", handleDrop);
        dragDropRef.current.removeEventListener("dragenter", handleDragEnter);
        dragDropRef.current.removeEventListener("dragleave", handleDragLeave);
      }
    };
  }, []);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0].size <= allowedFileSize
      // ALLOWED_FILE_TYPES.includes(event.target.files[0].type)
    ) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const file = reader.result;

        setSelectedFile({
          file: file,
          imgFile: event?.target?.files?.[0],
        });
      });

      reader.readAsDataURL(event.target.files[0]);
    } else {
      console.log(">>> Error loading file");
    }
  };

  const onFileChange = (event: any) => {
    if (
      event.target.files &&
      event.target.files.length > 0 &&
      // ALLOWED_FILE_TYPES.includes(event.target.files[0].type) &&
      event.target.files[0].size >= allowedFileSize
    ) {
      // TO-DO show Error msg if the file size exceeds the limit
    } else {
      handleFileChange(event);
    }
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const uploadDocIcon =
    currentTheme === "light" ? uploadDocIconLight : uploadDocIconDark;

  return (
    <div className={`upload_container ${styles.upload_container}`} data-testid="upload_document_testID">
      <div className={`${styles.modal_title}`}>{t("uploadDocument")}</div>
      <div
        className={`upload_wrapper ${styles.upload_wrapper} ${isDragOver ? `uploading_state ${styles.uploading_state}` : ""}`}
        ref={dragDropRef}
      >
        <input
          type="file"
          // accept="application/pdf,
          // application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          // application/msword,
          // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
          // application/vnd.ms-excel,
          // application/vnd.openxmlformats-officedocument.presentationml.presentation,
          // application/vnd.ms-powerpoint"
          onChange={onFileChange}
          style={{ display: "none" }}
          ref={inputRef}
        />
        <div className={`upload_content ${styles.upload_content}`}>
          <div className={styles.icon_wrapper}>
            <img src={uploadDocIcon} alt={t("upload")} />
          </div>
          <div className={`${styles.upload_text}`}>{t("dragNDropMsg")}</div>
          <div className={`${styles.upload_text}`}>{t("or")}</div>
          <div className={styles.upload_text} onClick={handleClick}>
            {t("chooseFromLocalDevice")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadComponent;
