import { ReactComponent as BronzeIcon } from "../assets/images/awardIcon/bronzeIcon.svg";
import { ReactComponent as CrownAmbassador } from "../assets/images/awardIcon/crownAmbassador.svg";
import { ReactComponent as CrownIcon } from "../assets/images/awardIcon/crownIcon.svg";
import { ReactComponent as DiamondIcon } from "../assets/images/awardIcon/diamondIcon.svg";
import { ReactComponent as DoubleDiamond } from "../assets/images/awardIcon/doubleDiamond.svg";
import { ReactComponent as EmeraldIcon } from "../assets/images/awardIcon/emeraldIcon.svg";
import { ReactComponent as ExecutiveDiamond } from "../assets/images/awardIcon/executiveDiamond.svg";
import { ReactComponent as GoldIcon } from "../assets/images/awardIcon/goldIcon.svg";
import { ReactComponent as PlatinumIcon } from "../assets/images/awardIcon/platinumIcon.svg";
import { ReactComponent as RubyIcon } from "../assets/images/awardIcon/rubyIcon.svg";
import { ReactComponent as SapphireIcon } from "../assets/images/awardIcon/sapphireIcon.svg";
import { ReactComponent as SilverIcon } from "../assets/images/awardIcon/silverIcon.svg";
import { ReactComponent as TripleDiamond } from "../assets/images/awardIcon/tripleDiamond.svg";
import { ReactComponent as FoundersCrown } from "../assets/images/awardIcon/foundersCrown.svg";
import { ReactComponent as FoundersCrownAmbassador } from "../assets/images/awardIcon/foundersCrownAmbassador.svg";
import { ReactComponent as FoundersDiamond } from "../assets/images/awardIcon/foundersDiamond.svg";
import { ReactComponent as FoundersDoubleDiamond } from "../assets/images/awardIcon/foundersDoubleDiamond.svg";
import { ReactComponent as FoundersEmerald } from "../assets/images/awardIcon/foundersEmerald.svg";
import { ReactComponent as FoundersExecutiveDiamond } from "../assets/images/awardIcon/foundersExecutiveDiamond.svg";
import { ReactComponent as FoundersPlatinum } from "../assets/images/awardIcon/foundersPlatinum.svg";
import { ReactComponent as FoundersRuby } from "../assets/images/awardIcon/foundersRuby.svg";
import { ReactComponent as FoundersSapphire } from "../assets/images/awardIcon/foundersSapphire.svg";
import { ReactComponent as FoundersTripleDiamond } from "../assets/images/awardIcon/foundersTripleDiamond.svg";
import { ReactComponent as PlaceHolder } from "../assets/images/awardIcon/placeHolder.svg";
import { ReactComponent as TrackingAwardTagComeback } from "../assets/images/awardIcon/TrackingAwardTagComeback.svg";
import { ReactComponent as TrackingAwardTagIncrease } from "../assets/images/awardIcon/TrackingAwardTagIncrease.svg";
import { ReactComponent as TrackingAwardTagNew } from "../assets/images/awardIcon/TrackingAwardTagNew.svg";
import { ReactComponent as TrackingAwardTagDarkComeback } from "../assets/images/awardIcon/TrackingAwardTagDarkComeback.svg";
import { ReactComponent as TrackingAwardTagDarkIncrease } from "../assets/images/awardIcon/TrackingAwardTagDarkIncrease.svg";
import { ReactComponent as TrackingAwardTagDarkNew } from "../assets/images/awardIcon/TrackingAwardTagDarkNew.svg";


export const getAwardIcon = (award: string | undefined) => {
  switch (award?.toLowerCase()) {
    case "founders crown":
      return <FoundersCrown />;
    case "founders crown ambassador":
      return <FoundersCrownAmbassador />;
    case "founders diamond":
      return <FoundersDiamond />;
    case "founders double diamond":
      return <FoundersDoubleDiamond />;
    case "founders emerald":
      return <FoundersEmerald />;
    case "founders executive diamond":
      return <FoundersExecutiveDiamond />;
    case "founders platinum":
      return <FoundersPlatinum />;
    case "founders ruby":
      return <FoundersRuby />;
    case "founders sapphire":
      return <FoundersSapphire />;
    case "founders triple diamond":
      return <FoundersTripleDiamond />;
    case "bronze":
      return <BronzeIcon />;
    case "crown ambassador":
      return <CrownAmbassador />;
    case "crown":
      return <CrownIcon />;
    case "diamond":
      return <DiamondIcon />;
    case "double diamond":
      return <DoubleDiamond />;
    case "emerald":
      return <EmeraldIcon />;
    case "executive diamond":
      return <ExecutiveDiamond />;
    case "gold":
      return <GoldIcon />;
    case "platinum":
      return <PlatinumIcon />;
    case "ruby":
      return <RubyIcon />;
    case "sapphire":
      return <SapphireIcon />;
    case "silver":
      return <SilverIcon />;
    case "triple diamond":
      return <TripleDiamond />;
    default:
      return <PlaceHolder />;
  }
};

export const getTrackingAwardIconFromCode = (awardCode: string | number | undefined, currentTheme: string) => {
  switch (awardCode && awardCode?.toString()) {
    case "4":
      return currentTheme === "light" ? <TrackingAwardTagComeback /> : <TrackingAwardTagDarkComeback />;
    case "3":
      return currentTheme === "light" ? <TrackingAwardTagIncrease /> : <TrackingAwardTagDarkIncrease />;
    case "1":
      return currentTheme === "light" ? <TrackingAwardTagNew /> : <TrackingAwardTagDarkNew />;
    default:
      return "-";
  }
};

export const getTrackingAwardFromCode = (award: string | number | undefined, t: any) => {
  switch (award && award?.toString()) {
    case "1":
      return t("tracking_award_tag_names.New");
    case "2":
      return t("tracking_award_tag_names.Requalified");
    case "3":
      return t("tracking_award_tag_names.Increase");
    case "4":
      return t("tracking_award_tag_names.Comeback");
    default:
      return "-";
  }
};

export const getTrackingAwardColorFromCode = (award: string | number | undefined, currentTheme: string) => {
  switch (award && award?.toString()) {
    case "1":
      return currentTheme === "light" ? "#5969D6" : "#99B2F5";
    case "2":
      return currentTheme === "light" ? "#008499" : "##C082A1";
    case "3":
      return currentTheme === "light" ? "#417539" : "#B3D67B";
    case "4":
      return currentTheme === "light" ? "#DA7600" : "#FED773";
    default:
      return currentTheme === "light" ? "#000000" : "#D8D8D9"
  }
};

export const getTrackingAwardPinFromCode = (code: string | number | undefined) => {
  switch (code) {
    case "385":
      return "FDI";
    default:
      return "-";
  }
};

export const getAwardNameFromCode = (code: string | number | undefined, t: any) => {
  switch (code) {
    case "305": return t("award_names.Silver_Sponsor");
    case "310": return t("award_names.Silver");
    case "320": return t("award_names.Gold");
    case "330": return t("award_names.Platinum");
    case "340": return t("award_names.Ruby");
    case "342": return t("award_names.Founders_Platinum");
    case "345": return t("award_names.Founders_Ruby");
    case "350": return t("award_names.Pearl");
    case "365": return t("award_names.Sapphire");
    case "367": return t("award_names.Founders_Sapphire");
    case "370": return t("award_names.Emerald");
    case "375": return t("award_names.Founders_Emerald");
    case "380": return t("award_names.Diamond");
    case "385": return t("award_names.Founders_Diamond");
    case "390": return t("award_names.Executive_Diamond");
    case "393": return t("award_names.GAR_Executive_Diamond");
    case "395": return t("award_names.Founders_Executive_Diamond");
    case "398": return t("award_names.GAR_Founders_Executive_Diamond");
    case "400": return t("award_names.Double_Diamond");
    case "403": return t("award_names.GAR_Double_Diamond");
    case "405": return t("award_names.Founders_Double_Diamond");
    case "408": return t("award_names.GAR_Founders_Double_Diamond");
    case "410": return t("award_names.Triple_Diamond");
    case "413": return t("award_names.GAR_Triple_Diamond");
    case "415": return t("award_names.Founders_Triple_Diamond");
    case "418": return t("award_names.GAR_Founders_Triple_Diamond");
    case "420": return t("award_names.Crown");
    case "423": return t("award_names.GAR_Crown");
    case "425": return t("award_names.Founders_Crown");
    case "428": return t("award_names.GAR_Founders_Crown");
    case "430": return t("award_names.Crown_Ambassador");
    case "433": return t("award_names.GAR_Crown_Ambassador");
    case "435": return t("award_names.Founders_Crown_Ambassador");
    case "438": return t("award_names.GAR_Founders_Crown_Ambassador");
    case "440": return t("award_names.FCA_40");
    case "445": return t("award_names.FCA_45");
    case "450": return t("award_names.FCA_50");
    case "455": return t("award_names.FCA_55");
    case "460": return t("award_names.FCA_60");
    case "465": return t("award_names.FCA_65");
    case "470": return t("award_names.FCA_70");
    default: return "-";
  }
}

export const getCountryNameFromCode = (countryCode: string | undefined, t:any) => {
  switch (countryCode) {
    case "US":
      return t("countryNames.US");
    case "TH":
      return t("countryNames.TH");
    case "CA":
      return t("countryNames.CA");
    case "DO":
      return t("countryNames.DO");
    default:
      return '-'
  }
};