import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Compensation.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import { isEmpty } from "lodash";
import ComingSoonBanner from "../../../common/ComingSoonBanner/ComingSoonBanner";

const Compensation = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);

  const isLoadingCompensation = false;
  const CompensationData: any = [];
  return (
    <div className={`compensation_wrapper ${styles.compensation_wrapper}`}>
      <div className={styles.heading_area}>
        <span className={styles.heading} data-testid="compensation_bg_heading_testID">
          {t("qualification_compensation")}
        </span>
      </div>
      {isLoadingCompensation || isEmpty(CompensationData) ? (
        <div className={styles.empty_state}>
          <div className={`table_no_data ${styles.empty_state_msg}`}>
            <ComingSoonBanner />
          </div>
        </div>
      ) : (
        <>
          <div className={`legAnalysis_section ${styles.compensation_section}`}>
          </div>
        </>
      )}
    </div>
  );
};

export default Compensation;
