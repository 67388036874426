import React from "react";
import styles from "./Sidebar.module.scss";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import { SIDE_NAV_LINKS } from "../../../constants/dataConstants";

interface SidePanelProps {
  onClickHandler: (tabID: string, elementRefMap: Record<string, React.RefObject<HTMLDivElement>>) => void;
  activeLink: string;
  elementRefMap: Record<string, React.RefObject<HTMLDivElement>>;
}

const SidePanel = ({ onClickHandler, activeLink, elementRefMap }: SidePanelProps) => {
  const { t } = useTranslation();

  return (
    <aside className={`sidebar_container ${styles.sidebar_container}`}>
      {map(SIDE_NAV_LINKS, (topLinkItem, index) => (
        <div
          className={styles.section}
          key={`${topLinkItem?.mainTabID}+${index}`}
        >
          <div
            className={`section_title ${styles.section_title}`}
            onClick={() => onClickHandler(topLinkItem?.innerTabs[0].tabId, elementRefMap)}
          >
            {t(topLinkItem?.mainTabID)}
          </div>
          <ul className={styles.items}>
            {map(topLinkItem?.innerTabs, (innerTabLink, index) => (
              <li
                className={`link_item ${styles.link_item} ${activeLink === innerTabLink?.tabId ? `selected_link ${styles.selected_link}` : ""}`}
                onClick={() => {
                  onClickHandler(innerTabLink?.tabId, elementRefMap)                 
                }}
                key={index}
                data-testid={innerTabLink?.tabId}
              >
                {t(innerTabLink?.tabId)}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </aside>
  );
};

export default SidePanel;
