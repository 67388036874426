import { useEffect, useRef, useState } from "react";
import styles from "./YearDropdown.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { updateYear } from "../../../redux/slices/selectedYearSlice";
import { useTranslation } from "react-i18next";

const YearDropdown = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //Calculate current year
  const currentYear = new Date().getFullYear();
  const currentPyYear = currentYear.toString().slice(2);

  const selectedYear =
    useSelector((state: RootState) => state.selectedYear.selectedYear) ||
    currentPyYear;
  const [isOpen, setIsOpen] = useState(false);

  // Created an array of years: current year, one future year, and two previous years
  const years = Array.from(
    { length: 4 },
    (_, index) => currentYear - 2 + index,
  );
  years.sort((a, b) => b - a);
  const pyYears = years.map((year) => year.toString().slice(2));

  const handleYearSelect = (pyYears: string) => {
    dispatch(updateYear(pyYears));
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`dropdown_container ${styles.dropdown_container}`}
      ref={dropdownRef}
      data-testid="year_dropdown_wrapper_testID"
    >
      <div className={styles.dropdown_heading} onClick={toggleDropdown}>
        <button
          className={`dropdown_button ${styles.dropdown_button}`}
          data-testid="year_dropdown_testID"
        >
          {t("py")} {selectedYear.toString().slice(2)}
        </button>
        <span
          className={`${isOpen ? "up_arrow" : "down_arrow"} ${styles.arrow_size}`}
        ></span>
      </div>
      {isOpen && (
        <div
          className={`dropdown_menu ${styles.dropdown_menu}`}
          data-testid="year_dropdown_List_testID"
        >
          {years.map((year) => (
            <div
              key={year}
              className={`${year.toString() === selectedYear ? "dropdown_selected_item" : ""} ${styles.dropdown_item}`}
              onClick={() => handleYearSelect(year.toString())}
            >
              {t("py")} { year.toString().slice(2)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default YearDropdown;
