import React from "react";
import styles from "./BusinessInformation.module.scss";
import KeyKpis from "./KeyKPIs/KeyKpis";
import LegAnalysis from "./LegAnalysis/LegAnalysis";
import ProbabilityModeling from "./ProbabilityModeling/ProbabilityModeling";
import Compensation from "./Compensation/Compensation";
import useScroll from "../../../redux/slices/useScrollSlice";

interface BusinessInformationProps {
  activeLink: string;
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
  onSectionChange: (sectionId: string) => void;
}

const BusinessInformation: React.FC<BusinessInformationProps> = ({
  activeLink,
  refMap,
  onSectionChange,
}) => {
  const { handleClick } = useScroll(refMap, onSectionChange);

  return (
    <div className={styles.businessInformation_wrapper} data-testid="business_information_plan_testID" >
      <div className={`content_inner ${styles.businessInformation_inner_wrapper}`}>
        <div className={styles.section_wrapper} ref={refMap["key_kpis"]}>
          <KeyKpis />
        </div>
        <div className={styles.section_wrapper} ref={refMap["leg_analysis"]}>
          <LegAnalysis />
        </div>
        <div className={styles.section_wrapper} ref={refMap["probability_modeling"]}>
          <ProbabilityModeling />
        </div>
        <div className={styles.section_wrapper} ref={refMap["compensation"]}>
          <Compensation />
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;
