export const LANGUAGES = [
  { label: "English", code: "en" },
  { label: "Thai", code: "th" },
];

export const Supported_Lang = ["en", "th"];

export const SIDE_NAV_LINKS = [
  {
    mainTabID: "business_information",
    mainTabName: "Business Information",
    innerTabs: [
      {
        tabId: "key_kpis",
        tabName: "Key KPIs",
      },
      {
        tabId: "leg_analysis",
        tabName: "Leg Analysis",
      },
      {
        tabId: "probability_modeling",
        tabName: "Probability Modeling",
      },
      {
        tabId: "compensation",
        tabName: "Compensation",
      },
    ],
  },
  {
    mainTabID: "goals_and_plans",
    mainTabName: "Goals & Action Plans",
    innerTabs: [
      {
        tabId: "goals",
        tabName: "Goals",
      },
      {
        tabId: "documents",
        tabName: "Documents",
      },
      {
        tabId: "notes",
        tabName: "Progress Notes",
      },
    ],
  },
  {
    mainTabID: "personal_information",
    mainTabName: "Personal Information",
    innerTabs: [
      {
        tabId: "contact_information",
        tabName: "Contact Information",
      },
      {
        tabId: "IBO_background",
        tabName: "IBO Background",
      },
      {
        tabId: "succession_plan",
        tabName: "Succession Plan",
      },
    ],
  },
];

export const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
];

export const EXTERNAL_SITES_LIST = [
  {
    id: "mybiz",
    value: "MyBiz 2.0",
  },
  // TODO: Change the id for ED LOS when link is available
  {
    id: "mybiz",
    value: "ED LOS",
  },
  {
    id: "magic",
    value: "MAGIC",
  },
  {
    id: "boss",
    value: "BOSS",
  },
];

export const QUERY_STATUS = {
  ACTIVE: 1,
  EXPIRED: 2,
  SUSPENDED: 2,
  REVOKED: 3,
  LOGOUT: 9,
  INACTIVE: 9,
};

export const localeDateMappings: {
  [key: string]: {
    dateFormat: string;
    longDateFormat: string;
    dateTimeFormat: string;
  };
} = {
  ANA: {
    dateFormat: "MM/dd/yyyy",
    longDateFormat: "MMM dd, yyyy",
    dateTimeFormat: "MM/dd/yy h:mma",
  },
  THA: {
    dateFormat: "dd/MM/yyyy",
    longDateFormat: "dd MMM yyyy",
    dateTimeFormat: "dd/MM/yy h:mma",
  },
  // Add more mappings as needed
};

export const countryCodeMappings: { [key: string]: string } = {
  "010": "ANA",
  "200": "THA",
  "020": "CAN",
  "580": "DO",

  // Add more mappings as needed
};

export const currencyLocaleMappings: { [key: string]: string } = {
  THB: "th-TH",
  USD: "en-US",
  DOP: "es-DO",
  // Add more mappings as needed
};

export const countryLocaleMappings: { [key: string]: string } = {
  "200": "th-TH",
  "010": "en-US",
  // Add more mappings as needed
};

export const countryMappings: { [key: string]: string } = {
  "200": "th",
  "010": "us",
  // Add more mappings as needed
};

export const currencyMappings: { [key: string]: string } = {
  "200": "usd",
  "010": "thb",
  // Add more mappings as needed
};

export const footerLinks: {
  [key: string]: { privacyPolicy: string; termsConditions: string };
} = {
  ANA: {
    privacyPolicy: "https://www.amwayglobal.com/privacy-notice/united-states/",
    termsConditions:
      "https://www.amway.com/en_US/support-center/policies-and-terms#TermsofUseSubcategoryComponent",
  },
  THA: {
    privacyPolicy: "https://www.amwayglobal.com/privacy-notice/thailand/",
    termsConditions: "https://www.amway.co.th/terms-of-use",
  },
  CAN: {
    privacyPolicy: "https://www.amwayglobal.com/privacy-notice/canada/",
    termsConditions:
      "https://www.amway.ca/en_CA/support-center/policies-and-terms#TermsofUseSubcategoryComponent",
  },
  DO: {
    privacyPolicy:
      "https://www.amwayglobal.com/privacy-notice/dominican-republic/",
    termsConditions:
      "https://www.amway.ca/en_CA/support-center/policies-and-terms#TermsofUseSubcategoryComponent",
  },
  // Add more mappings as needed
};

export const awardGroupCodeMapping: { [key: string]: string } = {
  all_accounts: "000-999",
  diamond_above_default: "380-999",
  founders_platinum_emerald: "342-375",
  sp_gp_platinum: "310-330",
};

export const aboColumnMapping: {
  [key: string]: { columnName: string; valueMapping: string };
} = {
  "tracking-award": {
    columnName: "Tracking_Award",
    valueMapping: "trackingAward.awardName",
  },
  "monthly-revenue": {
    columnName: "Monthly_Revenue",
    valueMapping: "monthlyRevenue.revenue",
  },
  "monthly-contributor": {
    columnName: "Monthly_Contributors",
    valueMapping: "monthlyContributor.contributor",
  },
  "annual-revenue": {
    columnName: "Annual_Revenue YTD",
    valueMapping: "annualRevenue.revenue",
  },
  "annual-contributor": {
    columnName: "Annual_Contributors",
    valueMapping: "annualContributor.contributor",
  },
};


type FilterDataType = {
  id: string;
  name: string;
}

export const NOTES_SORT_LIST: FilterDataType[] = [
  {
    id: "newest_first",
    name: "Newest First",
  },
  {
    id: "oldest_first",
    name: "Oldest First"
  }
];
